import React from 'react';
import hexToRgba from 'hex-to-rgba';
import Image from 'gatsby-image';
import { ContentfulAsset } from '../../graphql-types';
import { SYSTEM_FONTS } from '../../styles/typography';

interface Props {
  image: ContentfulAsset;
  title: string;
  text: string;
  background: string;
}

export default function PerkCard({ image, title, text, background }: Props) {
  return (
    <div
      css={{
        flex: '0 1 auto',
        padding: '24px 20px',
        textAlign: 'center',
        borderRadius: 10,
        marginBottom: '1rem',
        backgroundColor: background || '#E2E8F7',
      }}
    >
      {image.fluid && (
        <Image
          fluid={image.fluid}
          css={{ display: 'block', margin: '0 auto 36px', maxWidth: 73 }}
        />
      )}
      <p
        css={{
          fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
          fontWeight: 700,
          fontSize: 20,
          color: '#160B2C',
          marginBottom: '0.25rem',
        }}
      >
        {title}
      </p>
      <p
        css={{
          fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
          fontSize: '0.875rem',
          fontWeight: 400,
          color: hexToRgba('#160B2C', 0.8),
          b: {
            fontWeight: 400,
          },
        }}
      >
        {text}
      </p>
    </div>
  );
}
