import React from 'react';
import Image from 'gatsby-image';
import hexToRgba from 'hex-to-rgba';
import { FormattedMessage } from 'react-intl';
import { ContentfulImageHeadingAndShortText } from '../graphql-types';
import { SYSTEM_FONTS } from '../styles/typography';
import { ReactComponent as RightArrowIcon } from '../assets/images/rightArrowBubble.svg';

interface ChatBubbleProps {
  pullRight?: boolean;
  role?: 'dietician' | 'user';
  variant?: 'primary' | 'secondary';
}

export default function ChatBubble({
  image,
  heading,
  shortText,
  role,
  pullRight,
  variant = 'primary',
}: ChatBubbleProps & ContentfulImageHeadingAndShortText) {
  const styles = {
    padding:
      variant === 'primary' ? '12px 28px 22px 14px' : '19px 12px 18px 12px',
    alignItems: variant === 'primary' ? 'stretch' : 'center',
  };

  return (
    <div
      css={{
        display: 'flex',
        marginTop: 33,
        maxWidth: 280,
        backgroundColor: '#fff',
        borderRadius: 10,
        boxShadow: `0 -4px 5px 0 rgba(22,11,44,0.10), 0 4px 5px 0 rgba(22,11,44,0.10)`,
        alignSelf: pullRight ? 'flex-end' : 'flex-start',
        ...styles,
      }}
    >
      <Image
        fixed={image.fixed}
        alt=""
        css={{
          display: 'block',
          width: 40,
          height: 40,
          borderRadius: 40 / 2,
          marginRight: 14,
        }}
      />
      <div style={{ flex: 1 }}>
        <span
          css={{
            fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 14,
            lineHeight: '16px',
            color: '#160B2C',
          }}
        >
          {heading}
        </span>
        {variant === 'primary' && (
          <span
            css={{
              display: 'block',
              fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
              fontSize: 8,
              lineHeight: '10px',
              color: '#1FABAE',
              marginBottom: 8,
            }}
          >
            {role === 'dietician' ? (
              <FormattedMessage
                id="homepage.hero.chatBubble.dietician"
                defaultMessage="Dietician"
              />
            ) : (
              <FormattedMessage
                id="homepage.hero.chatBubble.user"
                defaultMessage="User"
              />
            )}
          </span>
        )}
        <p
          css={{
            fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 10,
            color: hexToRgba('#160B2C', 0.79),
            margin: 0,
          }}
        >
          {shortText}
        </p>
      </div>
      {variant === 'secondary' && (
        <div css={{ marginLeft: 0 }}>
          <RightArrowIcon />
        </div>
      )}
    </div>
  );
}
