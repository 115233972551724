import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/core';
import Container from './Container';
import AppDownloadButtons from '../common/appbuttons/AppDownloadButtonsRow';
import Text from './Text';
import presets from '../../styles/presets';
import backgroundMobile from '../../assets/images/download-app-background-mobile.svg';
import backgroundDesktop from '../../assets/images/download-app-background.svg';
import { SYSTEM_FONTS } from '../../styles/typography';
import { MonoFontLabel } from '../common/typography';
import { isBrowser, isMobile } from 'react-device-detect';
import { qrCode } from '../../utils/constants';

interface DownloadAppProps {
  /**
   * Determines whether the component is followed by another full width background vector.
   * In case it is, it will apply the negative margin-bottom, to allow following background to cover the one in this component.
   * @default false
   */
  individual?: boolean;
  isInsideContainer?: boolean;
}

export default function DownloadApp({
  individual = false,
  isInsideContainer = false,
}: DownloadAppProps) {
  return (
    <div
      css={css`
        padding-top: ${isInsideContainer
          ? isMobile
            ? '300px'
            : '230px'
          : '170px'};
        height: ${individual ? '732px' : '500px'};
        background-image: url(${backgroundMobile});
        background-position: 50% 0%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: ${individual ? '0px' : '-80px'};

        ${presets.lg} {
          padding-top: ${isInsideContainer ? '300px' : '230px'};
          height: ${individual ? '932px' : '828px'};
          margin-bottom: ${individual ? '0px' : '-200px'};
          background-image: url(${backgroundDesktop});
        }

        @media (min-width: 2350px) {
          height: 1080px;
          margin-bottom: ${individual ? '0px' : '-440px'};
        }

        @media (min-width: 3000px) {
          padding-top: 8vw;
          height: 32vw;
          margin-bottom: ${individual ? '0px' : '-16vw'};
        }
      `}
    >
      <Container css={{ textAlign: 'center' }}>
        <MonoFontLabel css={{ color: '#fff' }}>
          <FormattedMessage
            id="homepage.downloadApp.getStarted"
            defaultMessage="Get started"
          />
        </MonoFontLabel>
        <h4
          css={{
            fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 32,
            color: '#fff',
          }}
        >
          <FormattedMessage
            id="homepage.downloadApp.tryItForYourself"
            defaultMessage="Try it for yourself"
          />
        </h4>
        <AppDownloadButtons
          color="#150B2C"
          css={{
            flexDirection: 'column',
            [presets.sm]: { flexDirection: 'row' },
          }}
        />
        {isBrowser && (
          <div
            css={{
              display: 'block',
              visibility: 'visible',
              marginTop: 20,
            }}
          >
            <Text css={{ color: '#fff' }}>
              <FormattedMessage
                id="homepage.downloadApp.scanQrCodeToDownload"
                defaultMessage="Scan the QR code to download the app"
              />
            </Text>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div css={{ margin: '0 1.5rem' }}>
                <div>
                  <img src={qrCode} alt="Cara Care App Store QR Code" />
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
